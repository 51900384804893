import {defineStore} from 'pinia'

export const useConfigStore = defineStore('config', {
  persist: true,
  state: () => ({
    configuracoes: null,
    timestamp: Date.now(),
    preferenciaFiltroData: null, //usado na busca de requerimentos, memoriza a prefencia dos filtros
    preferenciaFiltroStatus: null, // ||
  }),
  actions: {
    async carregarConfiguracoes() {
      const {httpSilent} = await import('@/plugins/axios')
      if (!this.configuracoes || Date.now() > (this.timestamp + 300000)) {
        const {data} = await httpSilent.get('/configuracoes')
        this.configuracoes = data.configuracoes
        this.timestamp = Date.now()
      }
    },
  }
})
